$alfa: white;
$beta: #39b549;
$gama: #32ADE3;
$delta: #8e908e;
$border: #a9a9a9;

$chicago-gray: white;
$tuna-gray: #8e908e;
$flush-orange: #32ADE3;

// bootstrap vars

$link-color: $beta;


$theme-colors: (
  "primary": $flush-orange,
  "secondary": $tuna-gray
);



@import "~bootstrap/scss/bootstrap";
