.custom-navbar {
  background-color: $alfa;
  padding: 20px 16px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
  &__logo {
    color: white;
    transition: 0.3s ease-in-out color;
    text-transform: uppercase;
    font-size: 14px;
    @media screen and (max-width: 768px) {
      padding: 30px 10px;
    }
  }
  .nav-item {
    @media screen and (max-width: 768px) {
      padding: 0 10px;
      border-bottom: 1px solid $tuna-gray;
    }
  }
  &__link {
    color: $delta;
    transition: 0.3s ease-in-out color;
    text-transform: uppercase;
    font-size: 14px;

    @media screen and (max-width: 768px) {
      padding: 10px 0 10px 0;
    }

    &:hover {
      color: $gama;
    }
  }
  &__icon {
    color: white;
  }
}
