.homepage-outer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: calc(100% - 100px);

  &--logged {
    height: calc(100% - 100px);
  }

  &__inner {
    background-color: #ffffff8a;
    height: 100%;
    padding: 100px 16px;
  }

  &__title {
    font-size: 40px;
  }

  &__title-huge {
    font-size: 60px;
    margin-bottom: 40px;
  }
}
