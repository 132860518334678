//.policy-single {
//  border: 4px solid #575756;
//  padding: 20px;
//  margin: 40px 0;
//}

.policy-single {
  border-radius: 3px;
  background: linear-gradient(to right, rgba(50,174,227,1) 0%, rgba(29,123,60,1) 100%);
  padding: 3px;
  margin: 40px 0;
  border: 0;
}

.policy-single-body {
  background-color: white;
  padding: 20px;
}
