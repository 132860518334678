.modal {
  .modal-content {
    border-radius: 0;
    border: 4px solid $tuna-gray;
  }
}

.btn {
  border-radius: 3px;
  border: 0px solid;
  background: linear-gradient(to right, rgba(50,174,227,1) 0%, rgba(29,123,60,1) 100%);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 30px 10px 30px;
  min-height: 46px;
  &:hover {
    background-image: linear-gradient(100deg, #1c91c2, #106a14);
    box-shadow: 0 3px 20px -6px rgba(84, 84, 120, 0.5);
  }
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:disabled {
    box-shadow: none;
    background: linear-gradient(to right, rgba(50, 174, 227, 1) 0%, rgba(29, 123, 60, 1) 100%);
  }
}

.form-container {
  padding: 1rem;

  input {
    border-radius: 0;
  }
}

.forgot-password-link {
  color: black;
  &:hover {
    color: black;
  }
}

.custom-input {
  border-radius: 3px !important;
  &:focus {
    border: 3px solid;
    background-color: white;
    border-image-source: linear-gradient(to right, rgba(50,174,227,1) 0%, rgba(29,123,60,1) 100%);
    border-image-slice: 1;
    outline: 0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
  }
}

.append-button {
  height: 38px;
}

.fp-container {
  max-width: 500px;
  padding: 20px;
}

.fp-input {
  height: 46px;
}

.text-color-main {
  color: #575756;
}

.text-color-grey {
  color: $delta;
}

.text-color-orange {
  color: $flush-orange;
}

button{
  &:focus{
    border: 0;
    box-shadow: none;
    outline: none;
  }
}

.custom-button {
  color: white !important;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.4vw !important;
  }
}
