.custom-auth-nav {
  background: linear-gradient(to right, rgba(50,174,227,1) 0%, rgba(29,123,60,1) 100%);
  padding: 12px 16px;
  height: 60px;

  &--logged {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 14px;
    text-transform: uppercase;
    color: white;
  }
  &__last-visited {
    color: white;
    font-size: 12px;
  }
  &__list {
    display: flex;
    justify-content: flex-end;
  }

  &__list-item {
    display: flex;
    align-items: center;
  }
  &__link {
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    padding: 5px;
    text-decoration: none !important;
    font-weight: 500;
    min-height: auto;
    &:hover {
      text-decoration: none !important;
      color: white;
    }
  }
  &__button-text {
      vertical-align: middle;
  }
  &__icon {
    vertical-align: middle;
    margin: 0 4px;
  }
}

.transparent {
  background: transparent !important;
  &:hover{
    background: transparent !important;
    box-shadow: unset !important;
  }
}
