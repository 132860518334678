html, body, #root {
  height: 100%;
}

#root {
  position: relative;
}

.main {
  height: 100%;
  > .content {
    flex: 1 0 0;
  }
  > footer {
    margin-top: auto;
  }
}

@import "variables";
@import "vendor";

// Components
@import "custom-navbar";
@import "custom-auth-nav";
@import "custom-list-group";
@import "custom-footer";
@import "policy-single";
@import "policy-card";

