.policy-container {
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
  border: 1px solid black;
  margin: 20px;
  padding: 20px;

  &__claim {
    max-width: 500px;
  }
}
.policy-card {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  min-height: 400px;
  border-radius: 0;
  border-top: 30px solid #585856;
  border-bottom: 30px solid #585856;
  max-width: 500px;
  width: 100%;

  @media screen and (max-width: 900px) {
    max-width: 100%;
    flex-direction: column;
  }


  &--first {
    border-left: 30px solid #585856;
    border-right: 15px solid #585856;
    @media screen and (max-width: 900px) {
      border: 15px solid #585856;
    }
  }

  &--last {
    border-left: 15px solid #585856;
    border-right: 30px solid #585856;
    @media screen and (max-width: 900px) {
      border: 15px solid #585856;
    }
  }

  &__price {
    font-size: 50px;
    color: $flush-orange;
  }

  &__ipt {
    font-size: 14px;
    margin-left: 10px;
  }

  &__button {
    background: $flush-orange;
    border: 1px solid $flush-orange;
    min-height: 38px;
    padding: 0 20px;
  }
}

.policy-quote {
  padding: 20px;
  &__list {
    li {
      display: flex;
      justify-content: space-between;
    }
  }
  &__title {
    color: $flush-orange;
    font-weight: 400 !important;
    border-bottom: 1px solid $flush-orange;
    margin-bottom: 10px;
    h5 {
      font-size: 16px;
    }
  }
  &__time {
    margin: 0 4px;
    color: #212529;
    font-weight: 400 !important;
  }
  &__date {
    margin: 0 4px;
    color: #212529;
    font-weight: 400 !important;
  }
}
