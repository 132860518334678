$dashboard-bg: #96979a9c;
$dashboard-orange: #ee751a;

.loading-overlay-relative {
  position: relative;
}

.loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $dashboard-bg;
  text-align:center;
  z-index: 1000;
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__spinner {
    transition: 0.5s ease-in-out background-color;
    background-color: $dashboard-orange;
  }
}
